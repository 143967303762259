import { createAction, props } from '@ngrx/store';
import { MatDialogRef } from '@angular/material/dialog';
import {
  AlarmAssignOrgUpdateRequest,
  AlarmUpdateRequest,
  RspndrAlarm,
  RspndrOrganisation,
} from './@core/@models/rspndr';
import { RspndrBrowserPreferences } from './tabs/browser-preferences/browser-preferences.component';

export const APP_ACTION_TYPES = {
  LOGOUT: '[Home] Logout',
  LOGOUT_SUCCESS: '[Home] Logout Success',
  LOCALE: '[Home] Locale',
  LOCALE_SUCCESS: '[Home] Locale Success',
  LOCALE_FAIL: '[Home] Locale Fail',
  PASSWORD: '[Home] Password',
  PASSWORD_SUCCESS: '[Home] Password Success',
  PASSWORD_FAIL: '[Home] Password Fail',
  LOAD_BROWSER_PREFERENCES: '[Home] Load Browser Preferences',
  LOAD_BROWSER_PREFERENCES_SUCCESS: '[Home] Load Browser Preferences Success',
  SAVE_BROWSER_PREFERENCES: '[Home] Load Browser Preferences',
  ALARM_DETAIL: '[App] Alarm Detail',
  ALARM_DETAIL_CLEAR: '[App] Alarm Detail Clear',
  ALARM_DETAIL_SUCCESS: '[App] Alarm Detail Success',
  ALARM_DETAIL_FAIL: '[App] Alarm Detail Fail',
  LOOKUP_LOAD_ORGANISATION: '[App] Lookup Load Organisation',
  LOOKUP_LOAD_ORGANISATION_SUCCESS: '[App] Lookup Load Organisation Success',
  LOOKUP_LOAD_ORGANISATION_FAIL: '[App] Lookup Load Organisation Fail',
  ALARM_CACHE_CLEAR: '[App] Alarm Cache Clear',
  ALARM_UPDATE_ADDRESS: '[App] Alarm Update Address',
  ALARM_UPDATE_ADDRESS_SUCCESS: '[App] Alarm Update Address Success',
  ALARM_UPDATE_ADDRESS_FAIL: '[App] Alarm Update Address Fail',
  ALARM_ASSIGN_GUARD: '[App] Alarm Assign Guard',
  ALARM_ASSIGN_GUARD_SUCCESS: '[App] Alarm Assign Guard Success',
  ALARM_ASSIGN_GUARD_FAIL: '[App] Alarm Assign Guard Fail',
  ALARM_GUARD_REASSIGN: '[App] Alarm Guard Reassign',
  ALARM_GUARD_REASSIGN_SUCCESS: '[App] Alarm Guard Reassign Success',
  ALARM_GUARD_REASSIGN_FAIL: '[App] Alarm Guard Reassign Fail',
  ALARM_CANCEL: '[App] Alarm Cancel',
  ALARM_FINISH: '[App] Alarm Finish',
  ALARM_REPUBLISH: '[App] Alarm Republish',
  ALARM_HOLD: '[App] Alarm Hold',
  ALARM_MANUAL_PROCESS: '[App] Alarm Hold Manual',
  ALARM_TAKEN_REVERT: '[App] Alarm Taken Revert',
  DEVICE: '[App] Device',
  SUBSCRIBER_UPLOAD: '[App] Subscriber Upload',
  SUBSCRIBER_UPLOAD_SUCCESS: '[App] Subscriber Upload Success',
  SUBSCRIBER_UPLOAD_FAIL: '[App] Subscriber Upload Fail',
  SUBSCRIBER_UPLOAD_CLEAR_ERROR_MESSAGE: '[App] Subscriber Upload Clear Error Message',
  NOOP: '[App] No Op',
};

export const logoutAction = createAction(APP_ACTION_TYPES.LOGOUT);
export const logoutSuccessAction = createAction(APP_ACTION_TYPES.LOGOUT_SUCCESS);
export const localeAction = createAction(APP_ACTION_TYPES.LOCALE, props<{ language: string }>());
export const localeSuccessAction = createAction(APP_ACTION_TYPES.LOCALE_SUCCESS);
export const localeFailAction = createAction(APP_ACTION_TYPES.LOCALE_FAIL);
export const passwordAction = createAction(
  APP_ACTION_TYPES.PASSWORD,
  props<{ payload: { oldPassword: string; newPassword: string } }>(),
);
export const passwordSuccessAction = createAction(APP_ACTION_TYPES.PASSWORD_SUCCESS);
export const passwordFailAction = createAction(APP_ACTION_TYPES.PASSWORD_FAIL);
export const loadBrowserPreferencesAction = createAction(APP_ACTION_TYPES.LOAD_BROWSER_PREFERENCES);
export const loadBrowserPreferencesSuccessAction = createAction(
  APP_ACTION_TYPES.LOAD_BROWSER_PREFERENCES_SUCCESS,
  props<{ browserPreferences: RspndrBrowserPreferences }>(),
);
export const saveBrowserPreferencesAction = createAction(
  APP_ACTION_TYPES.SAVE_BROWSER_PREFERENCES,
  props<{ browserPreferences: RspndrBrowserPreferences }>(),
);
export const alarmDetailAction = createAction(
  APP_ACTION_TYPES.ALARM_DETAIL,
  props<{ alarmId: string }>(),
);
export const alarmDetailClearAction = createAction(APP_ACTION_TYPES.ALARM_DETAIL_CLEAR);
export const alarmDetailSuccessAction = createAction(
  APP_ACTION_TYPES.ALARM_DETAIL_SUCCESS,
  props<{ alarmDetail: RspndrAlarm }>(),
);
export const alarmDetailFailAction = createAction(APP_ACTION_TYPES.ALARM_DETAIL_FAIL);
export const loadOrganisationsListAction = createAction(APP_ACTION_TYPES.LOOKUP_LOAD_ORGANISATION);
export const loadOrganisationsListSuccessAction = createAction(
  APP_ACTION_TYPES.LOOKUP_LOAD_ORGANISATION_SUCCESS,
  props<{ organisationsList: RspndrOrganisation[] }>(),
);
export const loadOrganisationsListFailAction = createAction(
  APP_ACTION_TYPES.LOOKUP_LOAD_ORGANISATION_FAIL,
);
export const alarmCacheClearAction = createAction(
  APP_ACTION_TYPES.ALARM_CACHE_CLEAR,
  props<{ alarmId: string }>(),
);
export const alarmUpdateAddressAction = createAction(
  APP_ACTION_TYPES.ALARM_UPDATE_ADDRESS,
  props<{ alarmUpdateRequestData: AlarmUpdateRequest }>(),
);
export const alarmUpdateAddressSuccessAction = createAction(
  APP_ACTION_TYPES.ALARM_UPDATE_ADDRESS_SUCCESS,
);
export const alarmUpdateAddressFailAction = createAction(
  APP_ACTION_TYPES.ALARM_UPDATE_ADDRESS_FAIL,
);
export const alarmAssignGuardAction = createAction(
  APP_ACTION_TYPES.ALARM_ASSIGN_GUARD,
  props<AlarmAssignOrgUpdateRequest>(),
);
export const alarmAssignGuardSuccessAction = createAction(
  APP_ACTION_TYPES.ALARM_ASSIGN_GUARD_SUCCESS,
);
export const alarmAssignGuardFailAction = createAction(APP_ACTION_TYPES.ALARM_ASSIGN_GUARD_FAIL);
export const alarmGuardReassignAction = createAction(
  APP_ACTION_TYPES.ALARM_GUARD_REASSIGN,
  props<AlarmAssignOrgUpdateRequest>(),
);
export const alarmGuardReassignSuccessAction = createAction(
  APP_ACTION_TYPES.ALARM_GUARD_REASSIGN_SUCCESS,
);
export const alarmGuardReassignFailAction = createAction(
  APP_ACTION_TYPES.ALARM_GUARD_REASSIGN_FAIL,
);
export const alarmCancelAction = createAction(
  APP_ACTION_TYPES.ALARM_CANCEL,
  props<{ alarmId: string; note: string }>(),
);
export const alarmFinishAction = createAction(
  APP_ACTION_TYPES.ALARM_FINISH,
  props<{ alarmId: string }>(),
);
export const alarmRepublishAction = createAction(
  APP_ACTION_TYPES.ALARM_REPUBLISH,
  props<{ alarmId: string }>(),
);
export const alarmHoldAction = createAction(
  APP_ACTION_TYPES.ALARM_HOLD,
  props<{ alarmId: string }>(),
);

export const alarmHoldManualAction = createAction(
  APP_ACTION_TYPES.ALARM_MANUAL_PROCESS,
  props<{ alarmId: string }>(),
);

export const alarmTakenRevertAction = createAction(
  APP_ACTION_TYPES.ALARM_TAKEN_REVERT,
  props<{ alarmId: string }>(),
);
export const deviceAction = createAction(APP_ACTION_TYPES.DEVICE);

export const subscriberUploadAction = createAction(
  APP_ACTION_TYPES.SUBSCRIBER_UPLOAD,
  props<{ tenantId: string; csvFile: File; dialogRef: MatDialogRef<unknown> }>(),
);

export const subscriberUploadSuccessAction = createAction(
  APP_ACTION_TYPES.SUBSCRIBER_UPLOAD_SUCCESS,
);

export const subscriberUploadFailAction = createAction(
  APP_ACTION_TYPES.SUBSCRIBER_UPLOAD_FAIL,
  props<{ errorMessage: string }>(),
);

export const subscriberUploadClearErrorMessageAction = createAction(
  APP_ACTION_TYPES.SUBSCRIBER_UPLOAD_CLEAR_ERROR_MESSAGE,
);
export const noOpAction = createAction(APP_ACTION_TYPES.NOOP);
